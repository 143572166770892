import * as React from 'react'

function SvgStrokeCube (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path d="M84.173 64.865v-29.1a4.48 4.48 0 10-4.4-7.63l-25.35-14.61a4.7 4.7 0 000-1.5 4.485 4.485 0 00-8.86 1.4v.17l-25 14.85a4.48 4.48 0 10-4.74 7.32v29.08a4.48 4.48 0 001.5 8.71 4.14 4.14 0 00.7-.06 4.24 4.24 0 001.51-.5l26 14.72v.27a4.49 4.49 0 004.49 3.76 4.2 4.2 0 00.7 0 4.5 4.5 0 003.78-4.19l25-15.32a4.48 4.48 0 003.2 1.37 4.23 4.23 0 00.7-.06 4.47 4.47 0 00.8-8.63zm-5.15 1.56a4.42 4.42 0 00-.82 3l-24.63 15.13a4.5 4.5 0 00-2.55-1.66v-28.55a4.48 4.48 0 003.44-5l25-14.77a4.46 4.46 0 001.74 1.15v29.12a4.46 4.46 0 00-2.18 1.58zm-32.67 18.27l-24.81-14a4.34 4.34 0 00.26-2.3 4.44 4.44 0 00-2.93-3.5v-29.13a4.61 4.61 0 001.44-.85l25.33 14.16a4.4 4.4 0 000 1.62 4.48 4.48 0 003.38 3.66v28.55a4.52 4.52 0 00-2.67 1.79zm3.67-67.48a4.23 4.23 0 00.7-.06 4.33 4.33 0 002.2-1l25.35 14.58a4.76 4.76 0 000 1.51 4 4 0 00.17.66l-24.71 14.59a4.46 4.46 0 00-7.25-.24l-25-14a4.31 4.31 0 00.33-2.06l25.34-15a4.48 4.48 0 002.87 1.02zm.39 35.24a2.49 2.49 0 112.07-2.85 2.5 2.5 0 01-2.07 2.85zm31.87-23.37a2.49 2.49 0 11-2.07 2.84 2.49 2.49 0 012.07-2.84zm-32.65-18.81a2.49 2.49 0 11-2.07 2.84 2.49 2.49 0 012.07-2.84zm-34.71 21.65a2.49 2.49 0 112.84 2.07 2.48 2.48 0 01-2.84-2.07zm2.84 39.6a2.49 2.49 0 01-.74-4.91 2.43 2.43 0 011.85.44 2.48 2.48 0 01-1.07 4.47zm32.65 18.19a2.49 2.49 0 112.07-2.84 2.5 2.5 0 01-2.07 2.84zm32.61-18.19a2.49 2.49 0 11-.78-4.91 2.47 2.47 0 012.84 2.06 2.49 2.49 0 01-2.06 2.85z" />
    </svg>
  )
}

export default SvgStrokeCube
