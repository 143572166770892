import * as React from 'react'

function SvgStrokeSettings (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path d="M50.077 42.884h-.09a7.14 7.14 0 00-5 2.06 7.07 7.07 0 00-2.11 5 7.16 7.16 0 007.11 7.21 7.14 7.14 0 00.09-14.27zm-.09 11.67a4.57 4.57 0 01-4.54-4.54 4.46 4.46 0 011.34-3.19 4.54 4.54 0 013.2-1.34 4.53 4.53 0 014.48 4.67 4.54 4.54 0 01-4.48 4.4z" />
      <path d="M83.697 56.764c-3.29-1.27-4.93-3.4-5-6.51-.07-3.29 1.56-5.53 5-6.86a1.3 1.3 0 00.82-1.38c-.25-2.51-3-9-4.45-10.72a1.3 1.3 0 00-1.53-.28c-3.25 1.52-6 1.15-8.27-1.14-2.27-2.29-2.71-5-1.17-8.39a1.29 1.29 0 00-.5-1.65 34.72 34.72 0 00-10.42-4.29 1.29 1.29 0 00-1.52.84c-1.11 3.22-3.1 4.9-6.1 5.12-3.57.27-6-1.41-7.36-5.11a1.31 1.31 0 00-1.53-.82 35.49 35.49 0 00-10.38 4.37 1.3 1.3 0 00-.48 1.66c1.56 3.28 1.21 6-1.07 8.32-2.28 2.32-5.05 2.74-8.38 1.21a1.31 1.31 0 00-1.66.5 35.31 35.31 0 00-4.23 10.38 1.31 1.31 0 00.82 1.51c3.34 1.23 5 3.35 5.07 6.51.07 3.16-1.55 5.53-5 6.86a1.3 1.3 0 00-.79 1.52 34.69 34.69 0 004.42 10.34 1.29 1.29 0 001.63.5c3.23-1.44 5.9-1.06 8.16 1.14s2.75 5 1.32 8.23a1.3 1.3 0 00.52 1.63 35.46 35.46 0 0010.37 4.23 1.15 1.15 0 00.26 0 1.44 1.44 0 001.23-.79 2.43 2.43 0 00.14-.28 6.89 6.89 0 0113-.17 1.7 1.7 0 002.16 1.06 35.78 35.78 0 009.44-3.9l.36-.24.11-.08a1.29 1.29 0 00.47-1.61c-1.32-3-1.06-5.51.8-7.68 2.29-2.66 5.12-3.22 8.67-1.71a1.29 1.29 0 001.61-.51 34.87 34.87 0 004.3-10.33 1.29 1.29 0 00-.84-1.48zm-5.16 9.55a9.16 9.16 0 00-10.55 2.82 9.05 9.05 0 00-1.62 9.36 33 33 0 01-7.63 3.16 9.36 9.36 0 00-8.75-5.5h-.14a9.5 9.5 0 00-8.38 5.54 32.24 32.24 0 01-7.63-3.12 9.06 9.06 0 00-2.32-10 9.11 9.11 0 00-10-2.08 32.48 32.48 0 01-3.2-7.57 9.1 9.1 0 005.67-8.91 9.08 9.08 0 00-5.69-8.44 32.5 32.5 0 013.09-7.62 9.08 9.08 0 0010.23-2.2 9 9 0 002-10.17 32.73 32.73 0 017.6-3.18 9.12 9.12 0 009.53 5.71 9 9 0 007.93-5.75 32.76 32.76 0 017.61 3.14 9.09 9.09 0 002.15 10.24 9.08 9.08 0 0010.19 2.07 37.24 37.24 0 013.13 7.6 9.49 9.49 0 00-.06 17.33 32.18 32.18 0 01-3.16 7.57z" />
      <path d="M49.987 32.934a17.09 17.09 0 1017.08 17.08 17.1 17.1 0 00-17.08-17.08zm0 32.67a15.59 15.59 0 1115.58-15.59 15.61 15.61 0 01-15.58 15.59z" />
    </svg>
  )
}

export default SvgStrokeSettings
