import * as React from 'react'

function SvgStrokeMarketing (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path d="M76.595 19.785h-52.93a3.93 3.93 0 00-3.92 3.92v39.12a5.74 5.74 0 005.73 5.73h23.79v6.25h-9.67a4 4 0 00-4 4 3.59 3.59 0 00.08.81l.12.6h28.49l.13-.6a4.28 4.28 0 00.08-.81 4 4 0 00-4-4h-9.73v-6.25h25.83a3.67 3.67 0 003.66-3.67v-41.44a3.67 3.67 0 00-3.66-3.66zm-13.61 58.94h-25.95a2.52 2.52 0 012.56-2.42h20.88a2.51 2.51 0 012.51 2.42zm-39.32-55.94h52.93a.66.66 0 01.66.66v5.56h-54.51v-5.3a.92.92 0 01.92-.92zm52.93 42.77h-51.12a2.73 2.73 0 01-2.73-2.73v-32.29h54.53v34.35a.67.67 0 01-.68.67z" />
      <path d="M68.315 35.735a3.61 3.61 0 00-3.61 3.61 3.58 3.58 0 00.74 2.17l-9.77 10.38a3.5 3.5 0 00-4.13.46l-7.4-5a3.61 3.61 0 10-6.9-1.48 3.58 3.58 0 00.41 1.63l-.06-.05-4.54 5.55a3.57 3.57 0 00-1.35-.27 3.62 3.62 0 102.6 1.11l4.29-5.21a3.53 3.53 0 002.3.86 3.62 3.62 0 002.39-.92l7.41 5a3.52 3.52 0 00-.37 1.43 3.635 3.635 0 107.27 0 3.57 3.57 0 00-.74-2.16l9.74-10.35a3.49 3.49 0 001.73.46 3.61 3.61 0 000-7.22zm-36.61 22.72a2.11 2.11 0 112.11-2.11 2.12 2.12 0 01-2.11 2.11zm9.15-10.45a2.11 2.11 0 112.11-2.11 2.12 2.12 0 01-2.11 2.11zm13.08 9.15a2.11 2.11 0 112.11-2.15 2.11 2.11 0 01-2.11 2.14zm14.38-15.7a2.11 2.11 0 112.11-2.11 2.11 2.11 0 01-2.11 2.11z" />
    </svg>
  )
}

export default SvgStrokeMarketing
