import * as React from 'react'

function SvgStrokeWeb (props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path d="M36 33.4h14.21a1 1 0 000-2H36a1 1 0 000 2zm0 8.84h14.21a1 1 0 000-2H36a1 1 0 000 2zm30.08 7.57a1.07 1.07 0 00-.07-.19.64.64 0 010-.07l-.08-.08-.14-.15-.15-.1-.18-.08h-.2a.35.35 0 00-.11 0H36a1 1 0 00-1 1v17.68a.71.71 0 000 .2.64.64 0 00.06.18.36.36 0 010 .07.65.65 0 00.13.16 1.26 1.26 0 00.28.19h.08a1 1 0 00.34.07h29.24a1 1 0 001-1V50a1.62 1.62 0 00-.05-.19zm-4.52 1.26L37 66V51.07zm-22 15.68l24.52-14.9v14.9z" />
      <path d="M75.13 37.17v-.05L56.55 17.8H30a5.51 5.51 0 00-5.5 5.5v53.4a5.51 5.51 0 005.5 5.5h40a5.51 5.51 0 005.5-5.5V37.51zM56.91 22.51l14 14.61h-7.08c-4 0-6.92-2.53-6.92-6zM70 79.2H30a2.5 2.5 0 01-2.5-2.5V23.3a2.5 2.5 0 012.5-2.5h24.91v10.31c0 4.64 3.75 8 8.92 8h8.63V76.7A2.5 2.5 0 0170 79.2z" />
    </svg>
  )
}

export default SvgStrokeWeb
